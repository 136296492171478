
import { PropType, computed, defineComponent, ref, nextTick, onMounted, getCurrentInstance, watch } from 'vue'
import * as _ from 'lodash'
import TextField from '../../inputs/TextField.vue'
import CustomActivatorSelect from '../../inputs/CustomActivatorSelect.vue'
import { ScheduledReport } from '../../../types/scheduledReport'
import WhatsappDhAdminSettings from './whatsappDhAdminSettings.vue'
import tgDhAdminSettings from './tgDhAdminSettings.vue'
import cfg from '../../../../config'
import { v4 as uuidv4 } from 'uuid'
import ValidationInput from '../../inputs/ValidationInput.vue'
import phoneValidator, { isValidPhone } from '../../../helpers/validators/phoneValidator'
import { onBeforeMount } from 'vue'
export default defineComponent({
    props: {
        value: {
            type: Object as PropType<ScheduledReport>,
            required: true,
        },
    },
    components: {
        TextField,
        CustomActivatorSelect,
        ValidationInput,
        WhatsappDhAdminSettings,
        tgDhAdminSettings,
    },
    setup(props, { emit }) {
        const disableText = ref(false)
        watch(props, props => {
            disableText.value = props.value.templateId === cfg.repostStaffId
        })
        const root = getCurrentInstance()!.proxy
        const update = (val: any[]) => {
            emit('input', Object.assign({}, props.value, { sendTo: val }))
            nextTick(() => {
                emit('validation-updated', Boolean(props.value.sendTo.length))
            })
        }
        const phoneNumbers = computed(() => props.value.sendTo.filter(el => el.type === 'phone'))
        const isAdmin = computed(() => cfg.adminsWhitelist.includes((root as any).$auth.user.sub))
        const addPhoneNumber = () => {
            const newVal = props.value.sendTo.concat({ id: uuidv4(), type: 'phone', value: '' })
            update(newVal)
            updateValidation()
        }
        const removePhoneNumber = (phoneNumberId: string) => {
            let copyVal = [...props.value.sendTo]
            copyVal = copyVal.filter(el => el.id !== phoneNumberId)
            update(copyVal)
            updateValidation()
        }
        const isValid = () => {
            if (props.value.sendChannels.includes('whatsappBasis')) {
                return phoneNumbers.value.every(el => isValidPhone(el.value))
            } else if (props.value.sendChannels.includes('whatsappDh')) {
                return waDhPhones.value.every(el => isValidPhone(el.value))
            } else if (props.value.sendChannels.includes('tgDh')) {
                return tgDhPhones.value.every(el => isValidPhone(el.value))
            }
            return true
        }

        const updateValidation = () => {
            nextTick(() => {
                emit('validation-updated', isValid())
            })
        }

        const clear = () => {
            if (props.value.sendChannels.includes('whatsappDh') || props.value.sendChannels.includes('tgDh'))
                (root.$children as any)[0].clear()
        }
        const checkPhoneId = () => {
            const newVal = [...props.value.sendTo];
            newVal.forEach((el, i) => {
                if (!el.id) {
                    newVal[i].id = uuidv4()
                }
            })
            update(newVal)
        }

        const waDhPhones = ref([])
        const updateWaDhPhones = (newPhones) => {
            waDhPhones.value = newPhones
        }
        onBeforeMount(() => {
            checkPhoneId()
        })

        const tgDhPhones = ref([])
        const updateTgDhPhones = (newPhones) => {
            tgDhPhones.value = newPhones
        }
        onBeforeMount(() => {
            checkPhoneId()
        })

        onMounted(() => {
            if (props.value.sendChannels.includes('whatsappBasis') && props.value.sendTo.length === 0) {
                addPhoneNumber()
            }
            updateValidation()
        })

        watch(() => props.value.sendChannels,
            () => {
                updateValidation()
            }
        )
        watch(() => waDhPhones.value,
            () => {
                updateValidation()
            },
            {deep: true}
        )
        watch(() => tgDhPhones.value,
            () => {
                updateValidation()
            },
            {deep: true}
        )

        return {
            update,
            clear,
            phoneNumbers,
            addPhoneNumber,
            removePhoneNumber,
            updateValidation,
            phoneValidator,
            isAdmin,
            disableText,
            waDhPhones,
            updateWaDhPhones,
            tgDhPhones,
            updateTgDhPhones,
        }
    },
})
