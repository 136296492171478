
import { computed, defineComponent, ref, shallowRef, watch, PropType, onMounted } from 'vue'
import * as _ from 'lodash'
import { useProject } from '../../hooks/useProject'
import BaseInputOutlined from '../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../inputs/CustomActivatorSelect.vue'
export default defineComponent({
    components: { BaseInputOutlined, CustomActivatorSelect },
    props: {
        value: { type: Array as PropType<any[]>, required: true },
        items: { type: Array as PropType<{ id: string | number; name: string }[]>, required: true },
        placeholder: { type: String },
        canDeleteFirstItem: { type: Boolean },
    },
    setup(props, { emit }) {
        const { project } = useProject()
        const updateItem = (index: number, v: string) => {
            const copy = _.clone(props.value)
            copy.splice(index, 1, v)
            emit('input', copy)
        }
        const removeItem = (index: number) => {
            const copy = _.clone(props.value)
            copy.splice(index, 1)
            emit('input', copy)
        }
        const addItem = () => {
            const copy = _.clone(props.value)
            copy.push(null)
            emit('input', copy)
        }

        return { updateItem, removeItem, addItem }
    },
})
