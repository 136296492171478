
import { PropType, computed, defineComponent, ref, nextTick, onMounted } from 'vue'
import * as _ from 'lodash'
import TextField from '../../inputs/TextField.vue'
import CustomActivatorSelect from '../../inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    components: { TextField, CustomActivatorSelect, BaseInputOutlined },
    setup(props, { emit }) {
        const sendChannelsList = [
            { id: 'whatsappBasis', title: 'whatsapp' },
            { id: 'whatsappDh', title: 'Умный помощник Data Heroes (whatsapp)' },
            { id: 'tgDh', title: 'Умный помощник Data Heroes (telegram)' },
        ]
        const update = (val: string) => {
            emit('input', Object.assign({}, props.value, { sendChannels: [val] }))
            nextTick(() => {
                emit('validation-updated', Boolean(props.value.sendChannels?.length))
            })
        }
        onMounted(() => {
            emit('validation-updated', Boolean(props.value.sendChannels.length))
        })
        const clear = () => {
            nextTick(() => {
                emit('validation-updated', false)
            })
        }
        return { update, clear, sendChannelsList, first: _.first }
    },
})
