
import { PropType, computed, defineComponent, ref } from 'vue'

import * as _ from 'lodash'

export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    components: {},
    setup(props, { emit }) {
        const update = (val: string) => {
            emit('input', Object.assign({}, props.value, { text: val }))
        }

        return { update }
    },
})
