
import { PropType, computed, defineComponent, nextTick, ref } from 'vue'
import { SCHEDULED_REPORT_NAME_MAX_LENGTH } from '@/vars/GCB2'
import * as _ from 'lodash'
import TextField from '../../inputs/TextField.vue'
export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    components: { TextField },
    setup(props, { emit }) {
        const titleRules = [
            (v: any) => !!v || 'Обязательно',
            (v: any) =>
                v.length <= SCHEDULED_REPORT_NAME_MAX_LENGTH ||
                `Меньше ${SCHEDULED_REPORT_NAME_MAX_LENGTH} символов`,
        ]

        const update = (val: string) => {
            emit('input', Object.assign({}, props.value, { title: val }))
        }
        const formInput = (e: any) => emit('validation-updated', e)
        const clear = () => {
            nextTick(() => {
                emit('validation-updated', false)
            })
        }
        return { update, formInput, clear, titleRules }
    },
})
