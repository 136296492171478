
import { PropType, computed, defineComponent, ref, nextTick, onMounted, watch } from 'vue'
import * as _ from 'lodash'
import TextField from '../../inputs/TextField.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import { ScheduledReport } from '../../../types/scheduledReport'
import { v4 as uuidv4 } from 'uuid'
import cfg from '../../../../config'
import api from '../../../api/api'
import SpinnerLoader from '../../SpinnerLoader.vue'
import SelectGroup from '../../ui/SelectGroup.vue'
import phoneValidator, { isValidPhone } from '../../../helpers/validators/phoneValidator'
import ValidationInput from '../../inputs/ValidationInput.vue'
export default defineComponent({
    props: {
        value: {
            type: Array as PropType<ScheduledReport['sendTo']>,
            required: true,
        },
        updateWaDhPhones: {
            type: Function,
            required: true
        }
    },
    components: { TextField, ValidationInput, SpinnerLoader, SelectGroup },
    setup(props, { emit }) {
        const phones = ref<any[]>([])
        const groups = ref<any[]>([])
        const loading = ref(true)
        const waChats = ref<{ id: string; name: string }[]>([])

        const selectedGroupIds = computed(() => groups.value.map(el => el.value))
        const setGroups = (groupsIds: string[]) => {
            groups.value = groupsIds
                .map(groupId => waChats.value.find(chat => chat.id === groupId))
                .map(el => ({ type: 'waChat', value: el?.id || null, id: uuidv4() }))
        }
        const addPhoneNumber = () => {
            phones.value.push({ type: 'waChat', value: '', id: uuidv4() })
        }
        const removePhoneNumber = (id: string) => {
            phones.value = phones.value.filter(el => el.id !== id)
        }

        watch(
            () => JSON.stringify(phones.value) + JSON.stringify(groups.value),
            val => {
                const newSendTo = phones.value
                    .map(el => ({ value: el.value.replace(/[^0-9]/g, '') + '@c.us', type: 'waChat' }))
                    .concat(groups.value.map(el => ({ type: 'waChat', value: el.value })))
                emit('input', newSendTo)
            },
            { deep: true }
        )
        watch(
            () => phones.value,
            () => props.updateWaDhPhones(phones.value)
        )
        const updateValidation = () => {
            nextTick(() => {
                emit('validation-updated', isValid())
            })
        }
        const isValid = () => {
            return phones.value.every(el => isValidPhone(el.value))
        }

        onMounted(async () => {
            loading.value = true
            waChats.value = await api.scheduledReport.getWaChats().then(res => res.data!)
            const sendToClone = _.cloneDeep(props.value)

            phones.value = sendToClone
                .filter(el => el.type === 'waChat' && !el.value.includes('@g.us'))
                .map(el => Object.assign({ value: el.value.replace(/[^0-9+]/g, '') }, el, { id: uuidv4() }))
            groups.value = sendToClone
                .filter(el => el.type === 'waChat' && el.value.includes('@g.us'))
                .map(el => Object.assign({}, el, { id: uuidv4() }))
            loading.value = false
        })
        const clear = () => {
            groups.value = []
            phones.value = []
        }
        return {
            clear,
            addPhoneNumber,
            removePhoneNumber,
            phones,
            loading,
            waChats,
            groups,
            selectedGroupIds,
            setGroups,
            phoneValidator,
            updateValidation
        }
    },
})
