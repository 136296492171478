
import { PropType, computed, defineComponent, ref, nextTick, onMounted, watch } from 'vue'
import * as _ from 'lodash'
import TextField from '../../inputs/TextField.vue'
import CustomActivatorSelect from '../../inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import { ScheduledReport } from '../../../types/scheduledReport'
import { v4 as uuidv4 } from 'uuid'
import cfg from '../../../../config'
import api from '../../../api/api'
import SpinnerLoader from '../../SpinnerLoader.vue'
import SelectGroup from '../../ui/SelectGroup.vue'
import phoneValidator from '../../../helpers/validators/phoneValidator'
import ValidationInput from '../../inputs/ValidationInput.vue'
export default defineComponent({
    props: {
        value: {
            type: Array as PropType<ScheduledReport['sendTo']>,
            required: true,
        },
        updateTgDhPhones: {
            type: Function,
            required: true,
        }
    },
    components: { TextField, CustomActivatorSelect, ValidationInput, SpinnerLoader, SelectGroup },
    setup(props, { emit }) {
        const phones = ref<any[]>([])
        const tgGroups = ref<any[]>([])
        const loading = ref(true)
        const tgGroupsList = ref<{ id: string; name: string }[]>([])
        const addPhoneNumber = () => {
            phones.value.push({ type: 'tgUser', value: '', id: uuidv4() })
        }
        const removePhoneNumber = (id: string) => {
            phones.value = phones.value.filter(el => el.id !== id)
        }
        watch(
            () => JSON.stringify(tgGroups.value) + JSON.stringify(phones.value),
            val => {
                const tgGroupSendTo = tgGroups.value.map(el => ({ type: 'tgGroupId', value: el }))
                const phonesSendTo = phones.value.map(el => ({
                    value: el.value.replace(/[^0-9+]/g, ''),
                    type: 'tgUser',
                }))
                const newSendTo = phonesSendTo.concat(tgGroupSendTo)
                emit('input', newSendTo)
            },
            { deep: true }
        )
        watch(
            () => phones.value,
            () => props.updateTgDhPhones(phones.value)
        )

        onMounted(async () => {
            loading.value = true
            tgGroupsList.value = await api.scheduledReport.getTgChats().then(res => res.data!)

            const sendToClone = _.cloneDeep(props.value)
            phones.value = sendToClone
                .filter(el => el.type === 'tgUser')
                .map(el => Object.assign({ value: el.value.replace(/[^0-9+]/g, '') }, el, { id: uuidv4() }))
            tgGroups.value = sendToClone.filter(el => el.type === 'tgGroupId').map(el => el.value)
            loading.value = false
        })
        const clear = () => {
            tgGroups.value = []
            phones.value = []
        }
        return {
            clear,
            phones,
            loading,
            tgGroups,
            tgGroupsList,
            addPhoneNumber,
            removePhoneNumber,
            phoneValidator
        }
    },
})
