
import { PropType, computed, defineComponent, getCurrentInstance, nextTick, onMounted, ref, watch } from 'vue'
import * as _ from 'lodash'
import TextField from '../../inputs/TextField.vue'
import CustomActivatorSelect from '../../inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import api from '../../../api/api'
import useRouter from '../../../hooks/useRouter'
import { Project } from '../../../types/main'
import { ScheduledReport, ScheduledReportTemplate } from '../../../types/scheduledReport'
import SpinnerLoader from '../../SpinnerLoader.vue'
import TimeZonePicker from '../../ui/TimeZonePicker.vue'
import TimeInput from '../../inputs/TimeInput.vue'
import cfg from "@root/config.js"
export default defineComponent({
    props: {
        value: {
            type: Object as PropType<ScheduledReport>,
            required: true,
        },
        scheduledReportTemplates: {
            type: Array as PropType<ScheduledReportTemplate[]>,
            required: true,
        },
    },
    components: {
        TextField,
        CustomActivatorSelect,
        BaseInputOutlined,
        SpinnerLoader,
        TimeInput,
        TimeZonePicker,
    },
    setup(props, { emit }) {
        const sendAtDay = ref(props.value.sendPrevDay)
        const disableCheckbox = ref(false)

        const root = getCurrentInstance()!.proxy
        const { route } = useRouter(root)
        const daysList = [
            { title: 'Понедельник', id: 1 },
            { title: 'Вторник', id: 2 },
            { title: 'Среда', id: 3 },
            { title: 'Четверг', id: 4 },
            { title: 'Пятница', id: 5 },
            { title: 'Суббота', id: 6 },
            { title: 'Воскресенье', id: 7 },
        ]
        const project = computed<Project>(() => root.$store.getters.projectById(route.value.params.id))
        const activeTemplate = computed(() =>
            props.scheduledReportTemplates.find(el => el.id === props.value.templateId)
        )
        const templateType = computed(() => activeTemplate.value?.type)
        const update = (val: number, key: string) => {
            emit('input', Object.assign({}, props.value, { [key]: val }))
            nextTick(() => {
                emit('validation-updated', Boolean(_.get(props.value, key)))
            })
        }
        onMounted(async () => {
            if(props.value.templateId === cfg.repostStaffId){
                sendAtDay.value = true
                disableCheckbox.value = true
                props.value.sendPrevDay = true
            }
            else{
                sendAtDay.value = false
                disableCheckbox.value = false
            }
        })
        const clear = () => {
            nextTick(() => {
                emit('validation-updated', false)
            })
        }
        return { update, clear, daysList, templateType, sendAtDay, disableCheckbox }
    },
})
